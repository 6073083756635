$(document).ready(function() {

	// For navbar appearance onscroll
	$(document).scroll(function() {
		var y = $(this).scrollTop();
		if (y > 70) {
			$('#navbar-content').fadeIn();
		} else {
			$('#navbar-content').fadeOut();
		}
	});

	// Image gallery
	$(document).delegate('*[data-toggle="lightbox"]', 'click', function(event) {
	    event.preventDefault();
	    $(this).ekkoLightbox();
	}); 

	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})


	// To disable flex if hero section container is bigger than hero section wrapper
    // Hero section wrapper
    var wrapperHeight = $('section.hero').height();
    var containerHeight = $('section.hero .container').height();

    if (containerHeight > wrapperHeight) {
        $('.hero .container').css({ "align-self" : "auto", "margin-top" : "100px" });
        $('.hero').css({ "height" : "auto" });
    }

    // Same on window resize
    $( window ).on("resize", function() {
        var wrapperHeight = $('section.hero').height();
        var containerHeight = $('section.hero .container').height();

        if (containerHeight > wrapperHeight) {
            $('.hero .container').css({ "align-self" : "auto", "margin-top" : "100px" });
            $('.hero').css({ "height" : "auto" });
        }
    });
});